import clsx from "clsx";
import React from "react";

interface ButtonProps {
  readonly children: React.ReactNode | string;
  readonly onClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  readonly type?: "button" | "submit" | "reset";
  readonly disabled?: boolean;
  readonly className?: string;
  readonly disabledClassName?: string;
  readonly isLoading?: boolean;
  readonly loaderClassName?: string;
  readonly isAIBtn?: boolean;
  readonly style?: any;
}

export default function Button({
  children,
  onClick,
  type,
  disabled,
  className,
  disabledClassName = "bg-gray-300",
  isLoading = false,
  loaderClassName = "border-white",
  isAIBtn = false,
  style,
}: ButtonProps) {
  return (
    <button
      className={clsx(
        `${
          (disabled || isLoading) && disabledClassName
        } ${className} relative bg-primary text-white rounded-md px-4 py-2 focus:outline-none transition-all duration-300 `,
        {
          "cursor-not-allowed": isAIBtn && (disabled || isLoading),
          "!bg-[#BDC0C3]": !isAIBtn && (disabled || isLoading),
        }
      )}
      onClick={onClick}
      type={type}
      disabled={disabled || isLoading}
      style={style}
    >
      {isLoading && (
        <div className="absolute top-0 left-[16px] h-full flex items-center justify-center">
          <div
            className={`w-4 h-4 border-t-2 border-primary border-solid rounded-full animate-spin ${loaderClassName}`}
          />
        </div>
      )}
      {children}
    </button>
  );
}
