import React, { useState } from "react";
import OrderIcon from "app/components/Icons/OrderIcon";
import TicketsFilter from "./TicketsFilter";
import SelectedFilter from "./SelectedFilter";
import { CheckIcon } from "app/components/Icons";
import IconText from "app/components/IconText";
import ResetIcon from "app/components/Icons/ResetIcon";
import SearchButton from "./SearchButton";
import { Filters } from "../helpers";
import { Filter } from "../types";

export default function FilterBar() {
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string[];
  }>({});
  const [saveFilter, setSaveFilter] = useState<Filter[]>([]);
  const [buttonsVisible, setButtonsVisible] = useState(false);

  // Remove a filter from selected filters and selected options
  const onDeleteFilter = (filter: { id: string; options: string[] }) => {
    setSelectedFilters(selectedFilters.filter((f) => f.id !== filter.id));
    setSelectedOptions((prevState) => {
      const updatedState = { ...prevState };
      delete updatedState[filter.id];
      return updatedState;
    });
  };

  // Handle resetting selected filters and options
  const handleReset = () => {
    setSelectedFilters([]);
    setSelectedOptions({});
    setSaveFilter([]);
    setButtonsVisible(false);
  };

  // Save the selected filters and merge them into the saveFilter state
  const handleSave = () => {
    setSaveFilter((prevSaveFilter) => {
      // Merge the new selected filters into saveFilter without duplicates
      const newFilters = selectedFilters.filter(
        (filter) =>
          !prevSaveFilter.some((savedFilter) => savedFilter.id === filter.id)
      );
      return [...prevSaveFilter, ...newFilters];
    });
    setButtonsVisible(false);
  };

  // Merge selectedFilters and saveFilter without duplicates for display on the UI
  const filtersToDisplay = [
    ...saveFilter,
    ...selectedFilters.filter(
      (filter) =>
        !saveFilter.some((savedFilter) => savedFilter.id === filter.id)
    ),
  ];

  return (
    <div className="flex flex-row gap-[10px] justify-between">
      <div className="flex flex-row gap-[10px]">
        <SearchButton />
        <button className="min-w-[32px] size-[32px] bg-white border border-[#E9ECF2] rounded-[4px] flex items-center justify-center hover:bg-[#E9ECF2] focus:outline-none outline-none">
          <OrderIcon size={20} color="#79808A" />
        </button>
        <div className="border border-[#E9ECF2] h-[32px] w-[0.5px]" />
        <div className="flex flex-row items-center gap-[10px] flex-wrap">
          <SelectedFilter
            options={filtersToDisplay}
            selectedOptions={selectedOptions}
            onSelectOption={(filterId, selectedOption) => {
              setSelectedOptions((prevState) => ({
                ...prevState,
                [filterId]: selectedOption,
              }));
            }}
            onDeleteFilter={onDeleteFilter}
          />
          <TicketsFilter
            options={Filters}
            saveFilter={saveFilter}
            selectedFilters={selectedFilters}
            onSelectFilter={(filter) => {
              setSelectedFilters(filter);
              setButtonsVisible(true);
            }}
          />
        </div>
      </div>

      {buttonsVisible && selectedFilters.length > 0 && (
        <div className="flex flex-row gap-[10px]">
          <button
            className="text-sm font-normal text-secondary h-[32px] flex flex-row items-center gap-[6px] justify-center px-[10px]"
            onClick={handleReset}
          >
            <IconText
              icon={<ResetIcon size={20} color="#79808A" />}
              text="Reset"
              className="text-sm font-medium mt-[2px] text-secondary"
            />
          </button>
          <button
            className="text-sm font-normal text-secondary h-[32px] flex flex-row items-center gap-[6px] justify-center bg-primary100 text-primary border border-primary rounded-[4px] px-[10px]"
            onClick={handleSave}
          >
            <IconText
              icon={<CheckIcon size={20} color="#5A8DF1" />}
              text="Save"
              className="text-sm font-medium mt-[2px] text-primary"
            />
          </button>
        </div>
      )}
    </div>
  );
}
