import React, { useState } from "react";
import CautionDangerIcon from "../Icons/CautionDangerIcon";
import InfoIcon from "../Icons/InfoIcon";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly id: string;
  readonly label?: string;
  readonly type?: string;
  readonly placeholder?: string;
  readonly error?: string;
  readonly value?: string;
  readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  readonly onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  readonly onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  readonly name?: string;
  readonly className?: string;
  readonly icon?: React.ReactNode;
  readonly containerClassName?: string;
  readonly labelClassName?: string;
  readonly inputClassName?: string;
  readonly endIcon?: React.ReactNode;
  readonly inputRef?: React.RefObject<HTMLInputElement>;
  readonly activeColor?: string;
  readonly innerContainerClassName?: string;
  readonly helperText?: string;
  readonly noBorder?: boolean;
}

export default function Input({
  id,
  label,
  type = "text",
  placeholder,
  error,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  name,
  icon,
  labelClassName,
  containerClassName,
  inputClassName,
  className,
  endIcon,
  inputRef,
  activeColor = "#253858",
  autoFocus = false,
  innerContainerClassName,
  helperText,
  noBorder = false,
}: InputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const clonedIcon =
    icon &&
    React.cloneElement(icon as React.ReactElement, {
      color: error ? "#EB5757" : isFocused || value ? activeColor : "#BDC0C3",
    });
  return (
    <div className={`relative ${containerClassName}`}>
      <div
        className={`flex flex-col gap-[6px] group ${innerContainerClassName}`}
      >
        {label && (
          <label
            htmlFor={id}
            className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}
          >
            {label}
          </label>
        )}
        <div
          className={`flex items-center gap-[8px] h-[48px] flex-row ${
            noBorder ? "" : "border rounded-[4px]"
          } py-[16px] px-[10px] ${inputClassName} ${
            noBorder
              ? ""
              : isFocused
              ? "!border-[#79808A] !border-[1px]"
              : "border-[#E9ECF2]"
          } ${error && "border-danger"}`}
        >
          {icon && <div>{clonedIcon}</div>}
          <input
            type={
              type === "password" ? (showPassword ? "text" : "password") : type
            }
            id={id}
            className={`text-sm rounded w-full text-gray-700 leading-[21px] focus:outline-none placeholder:text-[#BDC0C3] placeholder:font-normal placeholder:text-sm placeholder:leading-[21px] ${className} `}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={(e) => {
              setIsFocused(false);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              setIsFocused(true);
              onFocus?.(e);
            }}
            onKeyDown={onKeyDown}
            name={name}
            ref={inputRef}
            autoFocus={autoFocus}
            autoComplete={type === "password" ? "new-password" : undefined}
          />
          {endIcon && type !== "password" && <div>{endIcon}</div>}
          {type === "password" && (
            <div
              className={`cursor-pointer hidden group-hover:block ${
                isFocused && "!block"
              }`}
            >
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="text-xs leading-[21px] text-primary"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          )}
        </div>
      </div>
      {helperText && !error && (
        <p className="text-[10px] leading-[14px] text-secondary absolute bottom-[-20px] flex items-center gap-[4px]">
          <InfoIcon size="16" />
          <span className="mt-[2px]">{helperText}</span>
        </p>
      )}
      {error && (
        <p className="text-[11px] leading-[18px] text-danger absolute bottom-[-18px] flex items-center gap-[4px]">
          <CautionDangerIcon />
          <span className="mt-[2px]">{error}</span>
        </p>
      )}
    </div>
  );
}
