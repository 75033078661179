import { EditIcon } from "app/components/Icons";
import CustomTooltip from "app/components/Tooltip";
import clsx from "clsx";
import React from "react";

interface EditButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  title?: string;
}

export default function EditButton({
  onClick,
  className,
  title,
}: EditButtonProps) {
  return (
    <CustomTooltip text={title ? title : "Edit"}>
      <button
        onClick={onClick}
        className={clsx(
          "flex items-center justify-center size-[20px] min-w-[20px] bg-white border border-[#E9ECF2] hover:bg-[#ECEEF2] rounded-[2px]",
          className
        )}
      >
        <EditIcon size={16} color="#79808A" />
      </button>
    </CustomTooltip>
  );
}
