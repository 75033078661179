import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectBoards } from "app/containers/Features/containers/Boards/redux/selector";
import Row from "./Row";
import Archive from "./Archive";
import { Board } from "../../../types";

export default function Rows() {
  const boards = useSelector(selectBoards);
  const [archiveModal, setArchiveModal] = useState(false);  
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const [editingBoardId, setEditingBoardId] = useState<string | null>(null);

  const handleOnArchive = (board: Board) => {
    setArchiveModal(true);
    setSelectedBoard(board);
  };

  const handleBoardRename = (board: Board) => {
  // Toggle edit mode for the clicked board, ensuring only one board is editable at a time
    setEditingBoardId(prevId => (prevId === board.id ? null : board.id));
  };

  return (
    <>
      {boards.map((board) => (
        <Row
          key={board.id}
          board={board}
          onArchive={() => handleOnArchive(board)}
          handleBoardRename={() => handleBoardRename(board)}
          isEditing={editingBoardId === board.id}
          setEditingBoardId={setEditingBoardId}
        />
      ))}
      {archiveModal && <Archive onClose={() => setArchiveModal(false)} boardName={selectedBoard?.boardName} />}
    </>
  );
}