import React from 'react';

interface DeleteIconProps extends React.SVGProps<SVGSVGElement> {
    color?: string;
    size?: number;
}

export default function DeleteIcon({
    color = '#253858',
    size = 24
}:DeleteIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path d="M6.23899 19.9682L6.23907 19.9683C6.58192 20.3226 6.9996 20.5016 7.48659 20.5016H16.5072C17.0064 20.5016 17.428 20.3269 17.765 19.9778C18.1013 19.6295 18.2683 19.1949 18.2683 18.6811V5.58717H19.1969H19.2969V5.48717V4.42185V4.32185H19.1969H15.1826V3.60156V3.50156H15.0826H8.91116H8.81116V3.60156V4.32185H4.79688H4.69687V4.42185V5.48717V5.58717H4.79688H5.72545V18.6811C5.72545 19.1829 5.89688 19.6139 6.23899 19.9682ZM9.64225 17.2056V17.3056H9.74225H10.7708H10.8708V17.2056V7.6178V7.5178H10.7708H9.74225H9.64225V7.6178V17.2056ZM13.1229 17.2056V17.3056H13.2229H14.2515H14.3515V17.2056V7.6178V7.5178H14.2515H13.2229H13.1229V7.6178V17.2056ZM6.95402 18.6811V5.58717H17.0397V18.6811C17.0397 18.8143 16.9871 18.9406 16.8703 19.0622C16.7527 19.1833 16.6324 19.2362 16.5072 19.2362H7.48659C7.32685 19.2362 7.20207 19.1842 7.1039 19.0825C7.00547 18.9805 6.95402 18.8496 6.95402 18.6811Z" fill={color} stroke={color} stroke-width="0.2" />
        </svg>
    )
}
