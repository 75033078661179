import React, { FC, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import CheckboxActiveIcon from "app/components/Icons/CheckboxActiveIcon";
import SearchIcon from "app/components/Icons/SearchIcon";
import LabelIcon from "app/components/Icons/LabelIcon";
import CustomTooltip from "app/components/Tooltip";
import Pill from "app/components/Pill";
import Input from "app/components/Input";

const tagsList = [
    { label: "internal bug", backgroundColor: "#FEC7002E", textColor: "#FF197F" },
    { label: "change request", backgroundColor: "#FEC7002E", textColor: "#FEC700" },
    { label: "figma needed", backgroundColor: "#00ABE52E", textColor: "#00ABE5" },
    { label: "design", backgroundColor: "#00AB7B2E", textColor: "#00AB7B" },
    { label: "need to fix", backgroundColor: "#0022E52E", textColor: "#0022E5" },
];

export const LabelsButton: FC= () => {
    const [open, setOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSelect = (label) => {
        setSelectedOptions((prev) =>
            prev.includes(label)
                ? prev.filter((item) => item !== label)
                : [...prev, label]
        );
    };

    const filteredTags = tagsList.filter((tag) =>
        tag.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <DropdownMenu.Root open={open} onOpenChange={setOpen} modal={false}>
            <CustomTooltip text="Set tags" position="bottom">
                <DropdownMenu.Trigger className="flex flex-row items-center justify-center h-[28px] min-w-[28px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
                    <LabelIcon />
                    {selectedOptions.length > 0 && (
                        <span className="text-sm text-gray-700 bg-gray-200 px-2 py-1">
                            {selectedOptions.length} tag
                        </span>
                    )}
                </DropdownMenu.Trigger>
            </CustomTooltip>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="bg-white rounded-md shadow-md border border-[#E9ECF2] p-2 w-64 " sideOffset={5}>
                    <div className="flex items-center">
                        <Input
                            id="search"
                            icon={<SearchIcon size="17" color="#79808A" />}
                            placeholder="Search by tag name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full p-1 text-sm border-none focus:ring-0"
                        />
                    </div>
                    <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
                        TAGS
                    </div>
                    <div className="py-2 max-h-40 overflow-y-auto">
                        {filteredTags.map((tag) => (
                            <DropdownMenu.Item
                                key={tag.label}
                                className="flex items-center gap-2 px-4 py-3 hover:bg-gray-100 cursor-pointer  bg-opacity-20"
                                onSelect={(e) => {
                                    e.preventDefault();
                                    handleSelect(tag.label);
                                }}
                            >
                                {selectedOptions.includes(tag.label) ? <CheckboxActiveIcon /> : <CheckboxIcon />}
                                <Pill
                                    key={tag.label}
                                    backgroundColor={tag.backgroundColor}
                                    textColor={tag.textColor}
                                    name={tag.label}
                                    className="px-[6px] py-[2px] text-[12px] leading-[18px] font-medium"
                                />
                            </DropdownMenu.Item>
                        ))}
                    </div>
                    <div className="p-2 text-[#5A8DF1] cursor-pointer text-sm border-t border-[#E9ECF2]">
                        + New tag
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};
