import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Option } from "app/types";
import React from "react";
import DropdownIcon from "../Icons/DropdownIcon";

interface CustomProps<T> {
  options: T;
  activeColor?: string;
  value?: string;
  icon?: React.ReactNode;
  trigger?: React.ReactNode;
  inputClassName?: string;
  error?: string;
  open: boolean;
  placeholder?: string;
}

const DropdownMenuTriggerComponent: React.FC<CustomProps<Option[]>> = ({
  options,
  value,
  icon,
  activeColor,
  trigger,
  inputClassName,
  error,
  open,
  placeholder,
}) => {
  const clonedIcon =
    icon &&
    React.cloneElement(icon as React.ReactElement, {
      color: error ? "#EB5757" : open || value ? activeColor : "#BDC0C3",
    });
  return (
    <>
      <DropdownMenuTrigger asChild>
        {trigger || (
          <div
            className={`flex items-center gap-[8px] flex-row border rounded-[4px] py-[16px] px-[10px] h-[48px] ${inputClassName} ${
              open ? "border-[#79808A] !border-[1px]" : "border-[#E9ECF2]"
            } ${error && "border-danger"}`}
          >
            {icon && <div>{clonedIcon}</div>}
            <button className="flex items-center justify-between text-sm rounded w-full text-gray-700 leading-[21px] focus:outline-none placeholder:text-[#BDC0C3] placeholder:font-normal placeholder:text-sm placeholder:leading-[21px]">
              {value ? (
                <div className="flex flex-row items-center gap-[4px]">
                  {options.find((option) => option.value === value)?.icon && (
                    <div className="min-w-[20px] min-h-[20px]">
                      {options.find((option) => option.value === value)?.icon}
                    </div>
                  )}
                  <span className="font-normal text-sm leading-[21px] text-black">
                    {options.find((option) => option.value === value)?.label}
                  </span>
                </div>
              ) : (
                <span className="font-normal text-sm leading-[21px] text-[#BDC0C3]">
                  {placeholder}
                </span>
              )}
              <div
                className={`size-[16px] min-w-[16px] transition-transform duration-300 ease-in-out ${
                  open ? "rotate-180" : ""
                }`}
              >
                <DropdownIcon size={16} />
              </div>
            </button>
          </div>
        )}
      </DropdownMenuTrigger>
    </>
  );
};

export default DropdownMenuTriggerComponent;
