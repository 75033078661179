import FlagIcon from "app/components/Icons/FlagIcon";
import CustomTooltip from "app/components/Tooltip";
import React, { FC, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import checkMark from "assets/svgs/checkmark.svg";
import { TicketPriorities } from "../types";

export const PriorityButton: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<TicketPriorities | null>(null);  

     const TicketPriorityList = [
         { label: "Normal", type: TicketPriorities.NORMAL, icon: <FlagIcon color="#2AD95B"/> },
         { label: "High", type: TicketPriorities.HIGH, icon: <FlagIcon color="#FF8A00"/> },
         { label: "Urgent", type: TicketPriorities.URGENT, icon: <FlagIcon color="#FF4F4F"/> },
         { label: "Low", type: TicketPriorities.LOW, icon: <FlagIcon color="#20C6EB"/> },
     ];     
     const handleSelect = (selected) => {
         setSelectedType(selected);
         setOpen(false);
     };
 
    const selectedOption = TicketPriorityList.find(option => option.type === selectedType);    
    return (
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
          <CustomTooltip text="Set priority"  position="bottom"> 
            <DropdownMenu.Trigger className="flex flex-row items-center justify-center h-[28px] min-w-[28px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
                <div className="min-w-[16px]">
                    {selectedOption ? selectedOption.icon : <FlagIcon /> }
                </div>
                {selectedOption && <span className="ml-2 text-sm p-1">{selectedOption.label}</span>}
            </DropdownMenu.Trigger>
            </CustomTooltip>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    align="start"
                    className="bg-white rounded-[4px] shadow-lg border border-[#E9ECF2] p-2 min-w-[180px]"
                >
                    <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
                     PRIORITIES
                    </div>

                    {TicketPriorityList.map((option) => (
                        <DropdownMenu.Item
                            key={option.type}
                            className="cursor-pointer flex flex-row gap-[6px] items-center px-4 py-3 hover:bg-[#F4F5F7] text-sm text-secondary"
                            onSelect={() => handleSelect(option.type)}
                        >
                            {option.icon}
                            <span>{option.label}</span>
                            {selectedType === option.type && (
                                <img src={checkMark} alt="selected" className="size-[20px] ml-auto" />
                            )}
                        </DropdownMenu.Item>
                    ))}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}

