import React, { useState } from "react";
import addIcon from "assets/svgs/ic_plus_primary.svg";
import Story from "../components/Story";
import StoryIcon from "app/components/Icons/StoryIcon";
import { getTicketIcon } from "../../Home/containers/helpers";
import { TicketType } from "../../../types";
import AddStoryModal from "../../../components/AddStoryModal";
import AddButton from "../components/AddButton";

const STORIES = [
  {
    title: {
      name: "Implement User Authentication with Google OAuth for Account Creation and Login",
      color: "#253858",
    },
    task: "5",
    bug: "2",
  },
  {
    title: {
      name: "Integration Innovators",
      color: "#253858",
    },
    task: "5",
    bug: "2",
  },
  {
    title: {
      name: "Integration Innovators_1.0.1",
      color: "#253858",
    },
    task: "5",
    bug: "2",
  },
];

export default function Stories() {
  const [isAddStoryModalOpen, setIsAddStoryModalOpen] = useState(false);

  const handleAdd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsAddStoryModalOpen(true);
  };
  return (
    <div className="flex flex-col gap-[10px]">
      <Story
        icon={<StoryIcon size={16} isCanceled={true} color="#253858" />}
        title="Tickets without story"
        task="5"
        bug="2"
        color="#253858"
      />
      {STORIES.map((epic, index) => (
        <Story
          icon={
            <img
              src={getTicketIcon(TicketType.STORY)}
              alt="story"
              className="size-[16px]"
            />
          }
          key={index}
          title={epic.title.name}
          task={epic.task}
          bug={epic.bug}
          color={epic.title.color}
        />
      ))}
      <AddButton title="Story" onClick={handleAdd} />
      {isAddStoryModalOpen && (
        <AddStoryModal onClose={() => setIsAddStoryModalOpen(false)} />
      )}
    </div>
  );
}
