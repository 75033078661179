import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { TicketState } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: TicketState = {
  addNewTemplate: false,
  addTicketDes: false,
  addTicketActivityDes: false,
};

const formSlice = createSlice({
  name: "ticketDetailsState",
  initialState,
  reducers: {
    toggleAddTicketDescription: (state, action: PayloadAction<boolean>) => {
      state.addTicketDes = action.payload;
    },
    toggleAddNewTemplate: (state, action: PayloadAction<boolean>) => {
      state.addNewTemplate = action.payload;
    },
    toggleAddTicketActivityDes: (state, action: PayloadAction<boolean>) => {
      state.addTicketActivityDes = action.payload;
    },
  },
});

export const {
  actions,
  reducer: ticketDetailsReducer,
  name: ticketDetailsSlice,
} = formSlice;
