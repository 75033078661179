import React from "react";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { ticketGroupsSlice, ticketGroupsReducer } from "./redux/slice";
import Tickets from "./components/Tickets";
import ticketGroupsSaga from "./redux/saga";
import {
  ticketDetailsReducer,
  ticketDetailsSlice,
} from "../../../Ticket/redux/slice";

export default function BoardView() {
  useInjectReducer({ key: ticketGroupsSlice, reducer: ticketGroupsReducer });
  useInjectSaga({ key: ticketGroupsSlice, saga: ticketGroupsSaga });

  useInjectReducer({ key: ticketDetailsSlice, reducer: ticketDetailsReducer });
  useInjectSaga({ key: ticketDetailsSlice, saga: ticketGroupsSaga });

  const params = useParams();
  const id = params?.id;
  return (
    <Layout>
      <Tickets id={id!} />
    </Layout>
  );
}
