import React from "react";
import addIcon from "assets/svgs/ic_plus_primary.svg";

interface CustomProps {
  title: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddButton: React.FC<CustomProps> = ({ title, onClick }) => {
  return (
    <>
      <button
        className="flex flex-row w-fit items-center gap-[4px]"
        onClick={onClick}
      >
        <img src={addIcon} alt="add" className="size-[20px]" />
        <span className="text-sm leading-[24px] text-primary font-semibold mt-[2px] font-sans">
          {title}
        </span>
      </button>
    </>
  );
};

export default AddButton;
