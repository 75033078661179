import React, { FC, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import UserIcon from "app/components/Icons/UserIcon";
import CustomTooltip from "app/components/Tooltip";
import Input from "app/components/Input";
import MemberProfile from "app/components/MemberProfile";
import { CheckIcon, SearchIcon } from "lucide-react";
import { members } from "../../ProjectSettings/data";
import { IMember } from "../../ProjectSettings/types";
import checkMark from "assets/svgs/checkmark.svg";

export const AssigneeButton: FC = () => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");  
  const [selectedMember, setSelectedMember] = useState<IMember | null>(null);

  const searchMembers = (members: IMember[], keyword: string): IMember[] => {
    if (!keyword) return members;
    return members.filter(
      (member) =>
        member.name.toLowerCase().includes(keyword.toLowerCase()) ||
        member.role.toLowerCase().includes(keyword.toLowerCase())
    );
  };

  const handleSelect = (member: IMember) => {
    setSelectedMember(member);
    setOpen(false);
  };

  const filteredMembers = searchMembers(members, searchTerm);

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen} modal={false}>
      <CustomTooltip text="Set assignee" position="bottom">
        <DropdownMenu.Trigger className="flex flex-row items-center justify-center h-[28px] min-w-[28px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
          {selectedMember ? (
            <MemberProfile
              iconColor={selectedMember.icon.color}
              iconBgColor={selectedMember.icon.bgColor}
              iconText={selectedMember.icon.initials ?? "-"}
              name={selectedMember.name ?? "-"}
              statusColor="#F00"
              iconClassName="w-6 h-6"
            />
          ) : (
            <UserIcon />
          )}
        </DropdownMenu.Trigger>
      </CustomTooltip>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-white rounded-md shadow-md border border-[#E9ECF2] w-64"
          sideOffset={5}
        >
          <div className="flex items-center p-2">
            <Input
            id="search"
            icon={<SearchIcon size="17" color="#79808A" />}
            placeholder="Search by member’s name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          </div>
          <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">MEMBERS</div>
          <div className="py-2 max-h-[18rem]  overflow-y-auto">
            {filteredMembers.map((member) => (
              <DropdownMenu.Item
                key={member.id}
                className="flex items-center gap-2 px-1 hover:bg-gray-100 cursor-pointer"
                onSelect={(e) => {
                  e.preventDefault();
                  handleSelect(member);
                }}
              >
                <MemberProfile
                  iconColor={member.icon.color}
                  iconBgColor={member.icon.bgColor}
                  iconText={member.icon.initials}
                  name={member.name}
                  role={member.role}
                  statusColor="#F00"
                />
                  {selectedMember?.id === member.id&& (
                      <img src={checkMark} alt="selected" className="size-[20px]    ml-auto" />
                                )}
              </DropdownMenu.Item>
            ))}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
