import Modal from "app/components/Modal"
import React from "react"
import AddStory from "./AddStory"

interface AddStoryModalProps {
    onClose: () => void
}

export default function AddStoryModal({ onClose }: AddStoryModalProps) {
    return (
        <Modal
            onClose={onClose}
            submitText="Add"
            title="Add new story"
            contentClassName="!p-0"
            modalClassName="!w-[721px] !max-w-[721px]"
        >
            <AddStory />
        </Modal>
    )
}