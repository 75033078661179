import React from "react";
import { Filters } from "../helpers";
import TicketsFilterDropdown from "./TicketsFilterDropdown";

interface SelectedFilterProps {
    options: typeof Filters;
    selectedOptions: { [key: string]: string[] };
    onSelectOption: (filterId: string, selectedOption: string[]) => void;
    onDeleteFilter: (filter: { id: string; options: string[] }) => void;
}

export default function SelectedFilter({ options, selectedOptions, onSelectOption, onDeleteFilter }: SelectedFilterProps) {
    return (
        <>
            {options.map(option => (
                <TicketsFilterDropdown
                    key={option.id}
                    option={option}
                    selectedOptions={selectedOptions[option.id] || []}
                    onSelectOption={(selectedOption) => onSelectOption(option.id, selectedOption)}
                    onDeleteFilter={onDeleteFilter}
                />
            ))}
        </>
    );
}
