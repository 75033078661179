import Modal from "app/components/Modal"
import React from "react"

export default function Archive({
    boardName,
    onClose,
}: {
    readonly onClose: () => void
    readonly boardName: string | undefined
}) {
    return (
        <Modal
            onClose={onClose}
            title="Archive board"
        >
            <p className="text-secondary text-sm leading-[21px] font-medium">  Are you sure that you want to archive <strong>{boardName}</strong> ?</p>
        </Modal>
    )
}