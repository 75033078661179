import React, { useState, ReactNode } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import IconText from "app/components/IconText";
import { DropdownIcon, MenuIcons } from "app/components/Icons";
import clsx from "clsx";
import { getTicketIcon } from "../containers/Home/containers/helpers";
import { TicketTypeButton } from "./TicketTypeButton";
import { TicketType } from "../types";
import menuIcon from "assets/svgs/ic_menu-grey.svg";

export default function AddTicketsDropdown({ open, setOpen, setShowAddTask }) {
  const options = [
    {
      label: "Task",
      type: TicketType.TASK,
      icon: getTicketIcon(TicketType.TASK),
    },
    {
      label: "Bug",
      type: TicketType.BUG,
      icon: getTicketIcon(TicketType.BUG),
    },
    {
      label: "Story",
      type: TicketType.STORY,
      icon: getTicketIcon(TicketType.STORY),
    },
    {
      label: "Epic",
      type: TicketType.EPIC,
      icon: getTicketIcon(TicketType.EPIC),
    },
  ];
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger className="w-[24px] h-[24px] flex items-center justify-center focus:outline-none outline-none">
        <button
          className={`w-[24px] h-[20px] flex items-center justify-center hover:bg-[#E9ECF2] focus:outline-none outline-none ${
            open ? "border border-transparent" : ""
          }`}
        >
          <DropdownIcon size={20} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          className="bg-white rounded-[4px] shadow-lg border border-[#E9ECF2] p-2 min-w-[180px]"
        >
          <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
            TICKET TYPES
          </div>
                {options.map((option, index) => (
                    <DropdownMenu.Item
                        key={index}
                        className="cursor-pointer flex flex-row gap-[6px] items-center px-4 py-3 hover:bg-[#F4F5F7] text-sm text-secondary"
                        onSelect={() =>setShowAddTask(
                            {
                                show: true,
                                type: option?.type
                            }
                        )}
                    >
                        <img src={option.icon} alt="task-icon" className="size-[20px]" />
                        <span>{option.label}</span>
                    </DropdownMenu.Item>
                ))}
            </DropdownMenu.Content>
        </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
