import StatusIcon from "app/components/Icons/StatusIcon";
import CustomTooltip from "app/components/Tooltip";
import React, { FC, useState } from "react";
import { TicketStatus } from "../types";
import checkMark from "assets/svgs/checkmark.svg";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

export const TicketStatusButton: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<TicketStatus | null>(null);
    
    const TicketStatusList = [
        { label: "OPENED", type: TicketStatus.OPENED, icon: <StatusIcon color="#43D98C"/>, color: "#43D98C" },
        { label: "IN_PROCESS", type: TicketStatus.IN_PROCESS, icon: <StatusIcon color="#FEB355"/>, color: "#FEB355" },
        { label: "DUPLICATED", type: TicketStatus.DUPLICATED, icon: <StatusIcon color="#FF8FC5"/>, color: "#FF8FC5" },
        { label: "CLOSED", type: TicketStatus.CLOSED, icon: <StatusIcon color="#A394FB"/>, color: "#A394FB" },
    ];

    const handleSelect = (selected) => {
        setSelectedType(selected);
        setOpen(false);
    };

    const selectedOption = TicketStatusList.find(option => option.type === selectedType);

    return (
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
            <CustomTooltip text="Set status" position="bottom">
                <DropdownMenu.Trigger className="flex flex-row items-center min-w-[28px] h-[28px] px-[6px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
                    <div className="min-w-[16px]">
                        {selectedOption ? selectedOption.icon : <StatusIcon color="#79808A" />}
                    </div>
                    {selectedOption && <span className="ml-2 text-sm" style={{ color: selectedOption.color }}>{selectedOption.label}</span>}
                </DropdownMenu.Trigger>
                </CustomTooltip>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        align="start"
                        className="bg-white rounded-[4px] shadow-lg border border-[#E9ECF2] p-2 min-w-[180px]"
                    >
                        <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">STATUSES</div>

                        {TicketStatusList.map((option) => (
                            <DropdownMenu.Item
                                key={option.type}
                                className="cursor-pointer flex flex-row gap-[6px] items-center px-4 py-3 hover:bg-[#F4F5F7] text-sm text-secondary"
                                onSelect={() => handleSelect(option.type)}
                            >
                                {option.icon}
                                <span style={{ color: option.color }}>{option.label}</span>
                                {selectedType === option.type && (
                                    <img src={checkMark} alt="selected" className="size-[20px] ml-auto" />
                                )}
                            </DropdownMenu.Item>
                        ))}
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
    );
};
