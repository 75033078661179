import React, { useState, useRef, useEffect } from "react";
import { Board, BoardPermission, BoardType } from "../../../types";
import ListIcon from "app/components/Icons/ListIcon";
import KabanIcon from "app/components/Icons/KabanIcon";
import CustomTooltip from "app/components/Tooltip";
import UserGroupIcon from "app/components/Icons/UserGroupIcon";
import PasswordIcon from "app/components/Icons/PasswordIcon";
import { CogIcon } from "app/components/Icons";
import IconText from "app/components/IconText";
import EditButton from "../../../components/EditButton";
import CopyButton from "../../../components/CopyButton";
import FavoriteButton from "../../../components/FavoriteButton";
import Input from "app/components/Input";

interface EditableRowItemProps {
  isEditing: boolean;
  board: Board;
  setEditingBoardId: (boardId: string | null) => void;
  handleBoardRename: (board: Board) => void;
}

const getBoardIcon = (type: BoardType) => {
  return type === BoardType.LIST ? <ListIcon color="#79808A" /> : <KabanIcon color="#79808A" />;
};

const getPermissionIcon = (permission: BoardPermission) => {
  if (permission === BoardPermission.PUBLIC) {
    return (
      <CustomTooltip text="Public permission">
        <UserGroupIcon color="#79808A" />
      </CustomTooltip>
    );
  }
  if (permission === BoardPermission.PRIVATE) {
    return (
      <CustomTooltip text="Private permission">
        <PasswordIcon color="#79808A" size="20" />
      </CustomTooltip>
    );
  }
  return (
    <CustomTooltip text="Custom permission">
      <CogIcon color="#79808A" size={20} />
    </CustomTooltip>
  );
};

export default function EditableRowItem({ board, handleBoardRename, isEditing, setEditingBoardId }: EditableRowItemProps) {
  const [boardName, setBoardName] = useState(board.boardName);
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      inputRef.current.style.width = `${spanRef.current.offsetWidth + 10}px`; // Adjust width dynamically
    }
  }, [boardName]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setEditingBoardId(null); // Close edit mode when clicking outside
      }
    };

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, setEditingBoardId]);

  return (
    <>
      {/* Hidden span to measure text width */}
      <span ref={spanRef} className="absolute invisible whitespace-pre">
        {boardName || "Placeholder"}
      </span>
      
      {isEditing ? (
        <Input
          id="board-name"
          name="boardName"
          inputRef={inputRef}
          value={boardName}
          onChange={(e) => setBoardName(e.target.value)}
          icon={getBoardIcon(board.type)}
          inputClassName="!rounded-none !p-2 h-full min-h-[40px] text-sm leading-[21px] font-normal text-[#333E4D]"
          style={{ minWidth: "50px" }}
        />
      ) : (
       <span className="flex items-center gap-2">
        <IconText
          text={''}
          icon={getBoardIcon(board?.type)}
          iconClassName="size-[20px]"
          className="h-full min-h-[40px] gap-[6px]"
          textClassName="text-sm leading-[21px] font-normal text-[#333E4D]"
        />
        {board?.boardName} 
      </span>
      )}
      <div className="flex items-center justify-center">
        {getPermissionIcon(board.permission)}
      </div>
      <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
        <EditButton
          title="Rename"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleBoardRename(board);
          }}
        />
      </div>
      <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
        <CopyButton
          copyText={board.boardName}
          className="bg-white border border-[#E9ECF2] hover:bg-[#ECEEF2] rounded-[2px]"
        />
      </div>
      <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
        <FavoriteButton />
      </div>
    </>
  );
}
