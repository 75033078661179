import React, { FC, useState } from "react";
import { TicketType } from "../types";
import CustomTooltip from "app/components/Tooltip";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import checkMark from "assets/svgs/checkmark.svg";
import { getTicketIcon } from "../containers/Home/containers/helpers";

interface TicketTypeButtonProps {
  readonly type: TicketType; 
  readonly onTypeChange: (type: TicketType) => void;
}

export const TicketTypeButton: FC<TicketTypeButtonProps> = ({ type, onTypeChange }) => {
  const [open, setOpen] = useState<boolean>(false);
  const options = [
    { label: "Task", type: TicketType.TASK, icon: getTicketIcon(TicketType.TASK) },
    { label: "Bug", type: TicketType.BUG, icon: getTicketIcon(TicketType.BUG) },
    { label: "Story", type: TicketType.STORY, icon: getTicketIcon(TicketType.STORY) },
    { label: "Epic", type: TicketType.EPIC, icon: getTicketIcon(TicketType.EPIC) },
  ];

  const handleSelect = (selectedType: TicketType) => {
    onTypeChange(selectedType);
    setOpen(false); 
  };

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <CustomTooltip text="Set ticket type" position="bottom">
        <DropdownMenu.Trigger className="flex flex-row items-center gap-[4px] h-[28px] px-[6px] capitalize rounded-[4px] border border-[#E9ECF2] text-xs leading-[18px] font-medium text-secondary hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
          <div className="min-w-[16px]">
            <img src={getTicketIcon(type)} alt="task-icon" className="size-[16px]" />
          </div>
          {type}
        </DropdownMenu.Trigger>
        </CustomTooltip>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            className="bg-white rounded-[4px] shadow-lg border border-[#E9ECF2] p-2 min-w-[180px]"
          >
            <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
              TICKET TYPES
            </div>

            {options.map((option) => (
              <DropdownMenu.Item
                key={option.type}
                className="cursor-pointer flex flex-row gap-[6px] items-center px-4 py-3 hover:bg-[#F4F5F7] text-sm text-secondary"
                onSelect={() => handleSelect(option.type)}
              >
                <img src={option.icon} alt={`${option.label}-icon`} className="size-[20px]" />
                <span>{option.label}</span>
                {type === option.type && (
                  <img src={checkMark} alt="selected" className="size-[20px] ml-auto" />
                )}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
  );
};
