import React, { useState } from "react";
import { useQueryState } from "nuqs";
import menuIcon from "assets/svgs/ic_menu-grey.svg";
import { CogIcon, DuplicateIcon } from "app/components/Icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import IconText from "app/components/IconText";

export default function SettingsButton() {
  const [settings, setSettings] = useQueryState("settings");
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    {
      label: "Settings",
      value: "settings",
      icon: <CogIcon size={20} color="#79808A" />,
      action: () => setSettings("settings"),
    },
    {
      label: "Duplicate",
      value: "duplicate",
      icon: <DuplicateIcon size={20} color="#79808A" />,
      action: () => {},
    },
  ];

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="w-[24px] h-[24px] flex items-center justify-center focus:outline-none outline-none">
        <button
          className={`w-[24px] h-[24px] flex items-center justify-center hover:bg-[#E9ECF2] focus:outline-none outline-none ${
            isOpen ? "border border-secondary" : ""
          }`}
        >
          <img src={menuIcon} alt="menuIcon" className="size-[24px]" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          align="end"
          className="w-full bg-white py-[10px] px-0"
        >
          {options.map((option) => (
            <DropdownMenuItem
              className="px-4 py-3 hover:bg-[#F4F5F7] hover:border-none focus:outline-none border-none"
              key={option.value}
              onClick={option.action}
            >
              <IconText
                icon={option.icon}
                text={option.label}
                className="text-sm leading-[21px] text-secondary"
              />
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}
