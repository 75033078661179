import { StarIconActive } from "app/components/Icons";
import StarIcon from "app/components/Icons/StarIcon";
import React, { useEffect, useState } from "react";

const StartButton: React.FC = () => {
  const localStorageStar = localStorage.getItem("isStar");

  const [isStar, setIsStar] = useState(localStorageStar || "false");

  const handleChange = () => {
    if (isStar == "true") {
      setIsStar("false");
      localStorage.setItem("isStar", "false");
    } else {
      setIsStar("true");
      localStorage.setItem("isStar", "true");
    }
  };

  return (
    <>
      <button
        className="w-[24px] h-[24px] flex items-center justify-center"
        onClick={handleChange}
      >
        {isStar === "true" ? (
          <StarIconActive size={70} />
        ) : (
          <StarIcon size={70} color="#79808A" />
        )}
      </button>
    </>
  );
};

export default StartButton;
