import React, { useState } from "react";
import Epic from "../components/Epic";
import addIcon from "assets/svgs/ic_plus_primary.svg";
import ComponentIcon from "app/components/Icons/ComponentIcon";
import AddComponentModal from "../../../components/AddComponentModal";
import AddButton from "../components/AddButton";

const COMPONENTS = [
  {
    title: {
      name: "Tickets without component",
      color: "#253858",
    },
    story: "-",
    task: "5",
    bug: "2",
    canceled: true,
  },
  {
    title: {
      name: "Web app, onboarding",
      color: "#253858",
    },
    story: "2",
    task: "5",
    bug: "2",
    canceled: false,
  },
  {
    title: {
      name: "Web app, login process",
      color: "#253858",
    },
    story: "2",
    task: "5",
    bug: "2",
    canceled: false,
  },
];

export default function Components() {
  const [isAddComponentModalOpen, setIsAddComponentModalOpen] = useState(false);
  const handleAdd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsAddComponentModalOpen(true);
  };
  return (
    <div className="flex flex-col gap-[10px]">
      {COMPONENTS.map((component, index) => (
        <Epic
          icon={
            <ComponentIcon
              isCanceled={component.canceled}
              color={component.title.color}
            />
          }
          key={index}
          title={component.title.name}
          story={component.story}
          task={component.task}
          bug={component.bug}
          color={component.title.color}
        />
      ))}

      <AddButton title="Component" onClick={handleAdd} />
      {isAddComponentModalOpen && (
        <AddComponentModal onClose={() => setIsAddComponentModalOpen(false)} />
      )}
    </div>
  );
}
