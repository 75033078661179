import CalendarIcon from "app/components/Icons/CalendarIcon";
import CustomTooltip from "app/components/Tooltip";
import React, { FC } from "react";

export const CalendarButton: FC = () => {
    return (
      <CustomTooltip text="Set due date"  position="bottom"> 
        <button className="flex flex-row items-center justify-center h-[28px] min-w-[28px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
            <CalendarIcon />
        </button>
      </CustomTooltip>  
    )
}