import React from "react";
import TicketsIcon from "app/components/Icons/TicketsIcon";
import StatusIcon from "app/components/Icons/StatusIcon";
import CalendarIcon from "app/components/Icons/CalendarIcon";
import FlagIcon from "app/components/Icons/FlagIcon";
import ReleaseIcon from "app/components/Icons/ReleaseIcon";
import TargetReleaseIcon from "app/components/Icons/TargetReleaseIcon";
import SprintIcon from "app/components/Icons/SprintIcon";
import EnvironmentIcon from "app/components/Icons/EnvironmentIcon";
import SeverityIcon from "app/components/Icons/SeverityIcon";
import ComponentIcon from "app/components/Icons/ComponentIcon";
import UserIcon from "app/components/Icons/UserIcon";
import { SprintStatus, Filter } from "./types"


export const getSprintStatusClasses = (status: SprintStatus) => {
    switch (status) {
        case SprintStatus.CURRENT:
            return 'bg-success100 text-success'
        case SprintStatus.PREVIOUS:
            return 'bg-danger100 text-danger'
        case SprintStatus.NEXT:
        return 'bg-purple100 text-purple'
        default:
        return ''
    }
}

export const Filters: Filter[] = [
    {
      id: "ticket_type",
      name: "Ticket Type",
      icon: React.createElement(TicketsIcon, { color: '#79808A', size: 20 }),
      options: ["Tasks", "Bugs", "Stories", "Epics"],
      isSearchable: false
    },
    {
      id: "status",
      name: "Status",
      icon: React.createElement(StatusIcon, { color: '#79808A', size: 20 }),
      options: ["Open", "In Progress", "In Review", "Close", "Ready for develpement", "Ready for QA", "Ready for release"],
      isSearchable: false
    },
    {
      id: "due_date",
      name: "Due Date",
      icon: React.createElement(CalendarIcon, { color: '#79808A', size: 20 }),
      options: [],
      isSearchable: false
    },
    {
      id: "priority",
      name: "Priority",
      icon: React.createElement(FlagIcon, { color: '#79808A', size: 20 }),
      options: ["Low", "Normal", "High", "Urgent"],
      isSearchable: false
    },
    {
      id: "release",
      name: "Release",
      icon: React.createElement(ReleaseIcon, { color: '#79808A', size: 20 }),
      options: ["1.0", "1.2", "1.3"],
      isSearchable: false
    },
    {
      id: "target_release",
      name: "Target release",
      icon: React.createElement(TargetReleaseIcon, { color: '#79808A', size: 20 }),
      options: ["Previous", "Current", "Next", "2.0.0", "2.1.0", "2.2.0"],
      isSearchable: true
    },
    {
      id: "sprint",
      name: "Sprint",
      icon: React.createElement(SprintIcon, { color: '#79808A', size: 20 }),
      options: ["Previous", "Current", "Next", "Bugbusters", "Operation Warp Speed"],
      isSearchable: true
    },
    {
      id: "environment",
      name: "Environment",
      icon: React.createElement(EnvironmentIcon, { color: '#79808A', size: 20 }),
      options: ["Development", "Production", "Staging", "QA"],
      isSearchable: true
    },
    {
      id: "severity",
      name: "Severity",
      icon: React.createElement(SeverityIcon, { color: '#79808A', size: 20 }),
      options: ["Moderate", "Minor", "Major", "Critical"],
      isSearchable: false
    },
    {
      id: "component",
      name: "Component",
      icon: React.createElement(ComponentIcon, { color: '#79808A', size: 20 }),
      options: ["Web app onboarding", "Web app, login process", "Web apps, usermanagement", "Web app, dashboard"],
      isSearchable: true
    },
    {
      id: "assignee",
      name: "Assignee",
      icon: React.createElement(UserIcon, { color: '#79808A', size: 20 }),
      options: [],
      isSearchable: true
    },
    {
      id: "creator",
      name: "Creator",
      icon: React.createElement(UserIcon, { color: '#79808A', size: 20 }),
      options: [],
      isSearchable: true
    },
    {
      id: "creation_date",
      name: "Creation date",
      icon: React.createElement(CalendarIcon, { color: '#79808A', size: 20 }),
      options: [],
      isSearchable: true
    }
  ];