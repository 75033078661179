import React, { useState } from "react";

interface TextareaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  readonly id: string;
  readonly label?: string;
  readonly placeholder?: string;
  readonly error?: string;
  readonly value?: string;
  readonly onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  readonly onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  readonly onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  readonly onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  readonly name?: string;
  readonly className?: string;
  readonly icon?: React.ReactNode;
  readonly containerClassName?: string;
  readonly labelClassName?: string;
  readonly inputClassName?: string;
  readonly endIcon?: React.ReactNode;
  readonly inputRef?: React.RefObject<HTMLTextAreaElement>;
  readonly activeColor?: string;
  readonly innerContainerClassName?: string;
}

export default function Textarea({
  id,
  label,
  placeholder,
  error,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  name,
  icon,
  labelClassName,
  containerClassName,
  inputClassName,
  className,
  endIcon,
  inputRef,
  activeColor = "#253858",
  autoFocus = false,
  innerContainerClassName,
}: TextareaProps) {
  const [isFocused, setIsFocused] = useState(false);
  const clonedIcon =
    icon &&
    React.cloneElement(icon as React.ReactElement, {
      color: error ? "#EB5757" : isFocused ? activeColor : "#BDC0C3",
    });
  return (
    <div className={`relative ${containerClassName}`}>
      <div className={`flex flex-col gap-[6px] ${innerContainerClassName}`}>
        {label && (
          <label
            htmlFor={id}
            className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}
          >
            {label}
          </label>
        )}
        <div
          className={`flex items-center gap-[8px] !h-[144px] flex-row border rounded-[4px] py-[16px] px-[10px] ${inputClassName} ${
            isFocused ? "!border-[#79808A]" : "border-[#E9ECF2]"
          } ${error && "border-danger"}`}
        >
          {icon && <div>{clonedIcon}</div>}
          <textarea
            id={id}
            className={`text-sm rounded w-full text-gray-700 leading-[21px] focus:outline-none placeholder:text-[#BDC0C3] placeholder:font-normal placeholder:text-sm placeholder:leading-[21px] h-full resize-none ${className}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={(e) => {
              setIsFocused(false);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              setIsFocused(true);
              onFocus?.(e);
            }}
            onKeyDown={onKeyDown}
            name={name}
            ref={inputRef}
            autoFocus={autoFocus}
          />
          {endIcon && <div>{endIcon}</div>}
        </div>
      </div>
      {error && (
        <p className="text-[11px] leading-[18px] text-danger absolute bottom-[-18px]">
          {error}
        </p>
      )}
    </div>
  );
}
