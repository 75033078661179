import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { Option } from "app/types";
import React, { useEffect, useRef, useState } from "react";
import CautionDangerIcon from "../Icons/CautionDangerIcon";
import DropdownMenuContentComponent from "./DropdownMenuContentComponent";
import DropdownMenuTriggerComponent from "./DropdownMenuTriggerComponent";

// Select Component
interface SelectProps<T> {
  options: T;
  value?: string;
  error?: string;
  label?: string;
  id?: string;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  activeColor?: string;
  icon?: React.ReactNode;
  placeholder?: string;
  onChange?: (value: string) => void;
  innerContainerClassName?: string;
  menuContentClassName?: string;
  menuListItemClassName?: string;
  menuListItemLabelClassName?: string;
  allowSearch?: boolean;
  searchPlaceholder?: string;
  trigger?: React.ReactNode;
  searchValue?: string;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  unPaddedRole?: boolean;
  align?:"center" | "start" | "end" 
}

export const Select: React.FC<SelectProps<Option[]>> = ({
  options,
  value,
  error,
  label,
  id,
  containerClassName,
  inputClassName,
  labelClassName,
  activeColor,
  icon,
  placeholder,
  onChange,
  innerContainerClassName,
  menuContentClassName,
  menuListItemClassName,
  menuListItemLabelClassName,
  allowSearch,
  searchPlaceholder,
  trigger,
  searchValue = "",
  handleSearchChange,
  unPaddedRole = false,
  align="start"
}) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setDropdownWidth(containerRef.current.offsetWidth);
    }
  }, []);

  return (
    <div ref={containerRef} className={`relative ${containerClassName}`}>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <div className={`flex flex-col gap-[6px] ${innerContainerClassName}`}>
          {label && (
            <label
              htmlFor={id}
              className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}
            >
              {label}
            </label>
          )}
          <DropdownMenuTriggerComponent
            options={options}
            open={open}
            activeColor={activeColor}
            error={error}
            icon={icon}
            inputClassName={inputClassName}
            placeholder={placeholder}
            trigger={trigger}
            value={value}
          />
          <DropdownMenuContentComponent
            options={options}
            allowSearch={allowSearch}
            handleSearchChange={handleSearchChange}
            menuContentClassName={menuContentClassName}
            menuListItemClassName={menuListItemClassName}
            menuListItemLabelClassName={menuListItemLabelClassName}
            onChange={onChange}
            searchPlaceholder={searchPlaceholder}
            searchValue={searchValue}
            unPaddedRole={unPaddedRole}
            setOpen={setOpen}
            align={align}
          />
        </div>
        {error && (
          <p className="text-[11px] leading-[18px] text-danger absolute bottom-[-18px] flex items-center gap-[4px]">
            <CautionDangerIcon />
            <span className="mt-[2px]">{error}</span>
          </p>
        )}
      </DropdownMenu>
    </div>
  );
};
