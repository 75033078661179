import {
  DropdownMenuContent,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import React from "react";
import SearchIcon from "../Icons/SearchIcon";
import Input from "../Input";
import { Option } from "app/types";

interface CustomProps<T> {
  options: T;
  menuContentClassName?: string;
  allowSearch?: boolean;
  searchPlaceholder?: string;
  menuListItemLabelClassName?: string;
  menuListItemClassName?: string;
  searchValue?: string;
  onChange?: (value: string) => void;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  unPaddedRole?: boolean;
  setOpen: (boolean) => void;
  align?:"center" | "start" | "end" 
}

const DropdownMenuContentComponent: React.FC<CustomProps<Option[]>> = ({
  options,
  menuContentClassName,
  menuListItemLabelClassName,
  menuListItemClassName,
  allowSearch,
  searchPlaceholder,
  searchValue,
  onChange,
  handleSearchChange,
  unPaddedRole,
  setOpen,
  align
}) => {
  return (
    <>
      <DropdownMenuContent
        className={`${menuContentClassName} py-[10px] z-10 !bg-white rounded-[4px] shadow-[2px_2px_6px_0px_rgba(237,239,242,0.70)] border border-[#E9ECF2] max-h-[320px] overflow-y-auto  w-[300px]`}
        style={{ width: "100%" }} // Adjust the width here to fit content
        sideOffset={5}
        align={align?align:"start"} // dropdown aligns with the left edge of the trigger button
      >
        {allowSearch && (
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className="border-b border-[#E9ECF2] hover:outline-none"
          >
            <Input
              id="search"
              placeholder={searchPlaceholder}
              inputClassName="border-none"
              icon={<SearchIcon size="17" color="#79808A" />}
              value={searchValue}
              onChange={handleSearchChange}
            />
          </DropdownMenuItem>
        )}
        {options.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => {
              onChange?.(option.value);
                setOpen(false);
            }}
            className={`cursor-pointer hover:outline-none text-sm leading-[21px] font-normal text-secondary hover:bg-[#F4F5F7] px-[8px] pl-[16px] py-[10px] flex items-center gap-2 ${menuListItemClassName}`}
          >
            <div className="flex flex-1 items-start gap-2">
              {option.icon && (
                <div className="min-w-[20px] min-h-[20px]">{option.icon}</div>
              )}
              <div className="flex flex-col">
                <span
                  className={`text-sm leading-[21px] font-normal text-black ${menuListItemLabelClassName}`}
                >
                  {option.label}
                </span>
                {option.role && (
                  <span
                    className={`text-xs leading-[16px] font-normal text-[#79808A] ${
                      unPaddedRole ? "" : "ml-8"
                    } mt-1`}
                  >
                    {option.role}
                  </span>
                )}
              </div>
            </div>
            {option.tick && (
              <span className="text-[#5A8DF1] font-bold ml-auto">✓</span>
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </>
  );
};

export default DropdownMenuContentComponent;
