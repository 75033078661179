import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import IconText from "app/components/IconText";
import { DropdownIcon } from "app/components/Icons";
import clsx from "clsx";
import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import CheckboxActiveIcon from "app/components/Icons/CheckboxActiveIcon";
import MoreHorizontalIcon from "app/components/Icons/MoreHorizontalIcon";
import SearchIcon from "app/components/Icons/SearchIcon";
import DeleteIcon from "app/components/Icons/DeleteIcon";

interface TicketsFilterDropdownProps {
    option: {
        id: string;
        name: string;
        icon: React.ReactNode;
        options: string[];
        isSearchable: boolean;
    };
    selectedOptions: string[];
    onSelectOption: (option: string[]) => void;
    onDeleteFilter: (filter: { id: string; options: string[] }) => void;
}

export default function TicketsFilterDropdown({
    option, selectedOptions, onSelectOption, onDeleteFilter
}: TicketsFilterDropdownProps) {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectAll, setSelectAll] = useState(false);

    // Filter options based on the search term
    const filteredOptions = option.options.filter((opt) =>opt.toLowerCase().startsWith(searchTerm.toLowerCase()));

    const isSelected = option.options.some(option => selectedOptions.includes(option));
    const clonedIcon = React.cloneElement(option.icon as React.ReactElement, { color: isSelected ? "#5A8DF1" : "#79808A" });
    const selectedText = option.options.filter(option => selectedOptions.includes(option)).join(", ");
    const handleSelectAll = () => {
        if (selectAll) {
            onSelectOption([]); // Unselect all
        } else {
            onSelectOption(option.options); // Select all options
        }
        setSelectAll(!selectAll); // Toggle the select all state
    };

    const handleDeleteFilter = () => {
        onDeleteFilter(option); // Pass the entire filter object for deletion
        setOpen(false);
    };

    return (
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
            <DropdownMenu.Trigger asChild>
                <button
                 className={clsx(
                    "text-sm font-normal text-secondary h-[32px] max-w-[270px] flex flex-row items-center gap-[6px] justify-center bg-white border rounded-[54px] px-[10px] text-ellipsis whitespace-nowrap overflow-hidden",
                    open ? "border-secondary" : isSelected ? "border-primary" : "border-[#E9ECF2]"
                )}>
                    <IconText
                        icon={clonedIcon}
                        text={isSelected ? `${option.name}: ${selectedText}` : option.name}
                        className={clsx("text-sm font-medium mt-[2px] max-w-[250px] text-ellipsis whitespace-nowrap overflow-hidden", isSelected ? "text-primary" : "text-secondary")}
                    />
                    <DropdownIcon size={20} color={isSelected ? "#5A8DF1" : "#79808A"} />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content className="w-[250px] bg-white py-[10px] px-0" align="start">
                    <div className="flex items-center justify-between pl-4 pr-1 py-[12px] text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase border-b border-[#E9ECF2]">
                        <span>{option.name}</span>
                        <div className="flex items-center gap-2">
                            <button
                                className="text-blue-600 text-xs font-medium focus:outline-none text-[#5A8DF1] font-weight-500"
                                onClick={handleSelectAll}
                            >
                                {selectAll ? "Unselect All" : "Select All"}
                            </button>
                            
                            {/* Delete filter dropdown */}
                            <DropdownMenu.Root>
                                <DropdownMenu.Trigger asChild>
                                    <button className="flex items-center justify-center w-6 h-6 rounded hover:bg-[#E9ECF2]">
                                        <MoreHorizontalIcon size={16} color="#79808A" />
                                    </button>
                                </DropdownMenu.Trigger>

                                <DropdownMenu.Content className="min-w-[160px] bg-white rounded-[4px] border border-[#E9ECF2] shadow-md py-[5px] z-50" sideOffset={5}>
                                    <DropdownMenu.Item
                                        className="flex items-center gap-2 px-4 py-2 text-sm cursor-pointer hover:bg-[#E9ECF2] rounded-[4px] normal-case font-normal"
                                        onClick={handleDeleteFilter}
                                    >
                                        <DeleteIcon size={20} color="#79808A" />
                                        Delete filter
                                    </DropdownMenu.Item>
                                </DropdownMenu.Content>
                            </DropdownMenu.Root>
                        </div>
                    </div>

                    {option.isSearchable && (
                        <div className="relative px-4 py-2 border-b border-[#E9ECF2]">
                            <span className="absolute left-6 top-[50%] transform -translate-y-1/2">
                                <SearchIcon color="#79808A" size={"16"} />
                            </span>
                            <input
                                type="text"
                                placeholder={`Search by ${option.name.toLocaleLowerCase()} name...`}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2 w-full text-sm border rounded-[4px] outline-none focus:border-blue-400"
                            />
                        </div>
                    )}

                    <div className="max-h-[220px] overflow-y-auto custom-scrollbar">
                        {filteredOptions.map((type) => (
                            <DropdownMenu.Item
                                key={type}
                                className="px-4 py-3 hover:bg-[#F4F5F7] focus:outline-none cursor-pointer flex flex-row items-center gap-[10px]"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (selectedOptions.includes(type)) {
                                        onSelectOption(selectedOptions.filter(o => o !== type)); // Remove the selected option
                                    } else {
                                        onSelectOption([...selectedOptions, type]); // Add the selected option
                                    }
                                }}
                                >
                                {selectedOptions.includes(type) ? <CheckboxActiveIcon /> : <CheckboxIcon />}
                                <span className="text-sm leading-[21px] text-secondary font-medium mt-[2px]">{type}</span>
                            </DropdownMenu.Item>
                        ))}
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}