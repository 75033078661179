import React from "react";
import MemberIcon from "../MemberIcon";

interface MemberProfileProps {
  iconColor?: string; // Color of the text inside the icon
  iconBgColor?: string; // Background color of the circular icon
  iconText?: string; // Text inside the icon (e.g., initials)
  name: string; // User's name
  role?: string; // User's role (optional)
  statusColor?: string; // Status indicator color (e.g., green for online)
  showEndIcon?: boolean; // Whether to show the tick icon
  endIcon?: React.ReactNode; // Custom icon to replace the default check icon
  className?: string; // Additional classNames for the component
  iconClassName?: string; //Additional classNames for the rounded icon component
  statusClassName?: string; //Additional classNames for the status icon component
  titleClassName?: string; //Additional classNames for the title text
  roleClassName?: string; //Additional classNames for the role text
  iconHide?: boolean;
  showStartIcon?: boolean;
  startIcon?: React.ReactNode// Custom start icon 
  hours?: string// Custom hours show
  date?: string// Custom date show
  showDateTime?: boolean// Custom date time show
}

const MemberProfile: React.FC<MemberProfileProps> = ({
  iconColor = "white",
  iconBgColor = "#6A60F7",
  iconText,
  name,
  role,
  statusColor,
  showEndIcon = false,
  endIcon,
  showStartIcon = false,
  startIcon,
  className = "",
  iconClassName = "",
  statusClassName = "",
  titleClassName = "",
  roleClassName = "",
  hours = "",
  date = "",
  iconHide,
  showDateTime=false,
}) => {
  return (
    <div
      className={`flex w-full items-center justify-between gap-4 p-2 hover:bg-gray-50 rounded-md transition-colors ${className}`}
      aria-label={`Profile of ${name}`}
    >
      {/* Left Section: Icon and User Info */}
      <div className="flex items-center gap-4">
        {/* Circular Icon */}
        {!iconHide && (
          <MemberIcon
            iconColor={iconColor}
            iconBgColor={iconBgColor}
            iconText={iconText as string}
            statusColor={statusColor}
            iconClassName={iconClassName}
            statusClassName={statusClassName}
          />
        )}
        {showStartIcon && startIcon}
        {/* User Details */}
        <div className="flex flex-col">
          <span
            className={`${titleClassName} text-base font-normal text-[#172B4D] overflow-hidden whitespace-nowrap text-ellipsis`}
          >
            {name}
          </span>
          {role && (
            <span
              className={`${roleClassName} text-sm font-normal text-[#6B778C]`}
            >
              {role}
            </span>
          )}
        </div>
      </div>

      {/* Right Section: Custom or Default Tick Icon */}
      {showDateTime && (
        <div className="flex flex-col text-right">
          <span
            className={`${titleClassName} text-base font-normal text-[#172B4D] overflow-hidden whitespace-nowrap text-ellipsis`}
          >
            {hours}
          </span>
          {role && (
            <span
              className={`${roleClassName} text-sm font-normal text-[#6B778C]`}
            >
              {date}
            </span>
          )}
        </div>
      )}
      {/* Right Section: Custom or hour and date show */}
      {showEndIcon && endIcon}
    </div>
  );
};

export default MemberProfile;
